import { MaterialTableData } from '@insights/models';
import { AccountService, AlertService, NavigationService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { ExternalAccount } from '@shared/models/connectors';
import { AuthorizationRole, ExternalAccountKind, Integration } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { getExternalAccountKindFromIntegration } from '@shared/services/stores';
import { ConnectorsStore } from '@shared/services/stores/interfaces';
import _, { find } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

export interface ExternalAccountTableData extends MaterialTableData {
  readonly account: ExternalAccount;
}

export interface ExternalAccountListViewModel {
  readonly configId: string;
  readonly accounts: ExternalAccount[];
  readonly integrations: Integration[];
  readonly accountKinds: ExternalAccountKind[];
  readonly teachersById: Record<string, AccountModel>;
  readonly tableData: ExternalAccountTableData[];

  hasSettings: (kind: ExternalAccountKind) => boolean;
  hasMappings: (kind: ExternalAccountKind) => boolean;
  isAvailable: (kind: ExternalAccountKind) => boolean;

  deleteAccount: (externalAccountId: string) => Promise<void>;

  navigateToAddAccount: (kind: ExternalAccountKind) => Promise<void>;
  navigateToEditAccount: (externalAccountId: string, kind: ExternalAccountKind) => Promise<void>;
  navigateToEditSettings: (externalAccountId: string, kind: ExternalAccountKind) => Promise<void>;
  navigateToEditMappings: (externalAccountId: string, kind: ExternalAccountKind) => Promise<void>;
  navigateToAssociations: (externalAccountId: string, navigate: NavigateFunction) => Promise<void>;
  navigateToExternalContentMappings: () => Promise<void>;
  navigateToChangeIntegrations: () => Promise<void>;
}

export class AppExternalAccountListViewModel implements ExternalAccountListViewModel {
  constructor(
    private readonly _connectorsStore: ConnectorsStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _accountService: AccountService,
    public readonly configId: string,
    public readonly accounts: ExternalAccount[],
    public readonly integrations: Integration[],
    public readonly teachersById: Record<string, AccountModel>
  ) {}

  get accountKinds(): ExternalAccountKind[] {
    return this.getIntegrationAccountKinds();
  }

  get tableData(): ExternalAccountTableData[] {
    return this.accounts.map<ExternalAccountTableData>((account) => ({ account, id: account.id }));
  }

  hasSettings(kind: ExternalAccountKind): boolean {
    // Note: This actually has no impact, issue with current version of @material-table/core.
    switch (kind) {
      case 'blackbaud':
      case 'blackbaud-sky':
      case 'canvas':
      case 'managebac':
      case 'microsoft-teams':
        return true;
    }

    // Important: Because Google has a notion of "Save to all",
    // everything must be done under mappings.
    return false;
  }

  hasMappings(kind: ExternalAccountKind): boolean {
    // Note: Same, no impact, issue with current version of @material-table/core.
    switch (kind) {
      case 'blackbaud':
      case 'blackbaud-sky':
      case 'canvas':
      case 'google':
      case 'managebac':
      case 'microsoft-teams':
        return true;
    }

    return false;
  }

  isAvailable(kind: ExternalAccountKind): boolean {
    return this.canAddMultipleAccounts(kind) || find(this.accounts, { kind }) == null;
  }

  deleteAccount(externalAccountId: string): Promise<void> {
    return this._connectorsStore.deleteExternalAccount(externalAccountId);
  }

  navigateToAddAccount(kind: ExternalAccountKind): Promise<void> {
    return this.navigateToEditAccount('new', kind);
  }

  async navigateToEditAccount(externalAccountId: string, kind: ExternalAccountKind): Promise<void> {
    switch (kind) {
      case 'blackbaud':
        await this._navigationService.navigateToBlackbaudAccount(this.configId, externalAccountId);
        break;
      case 'blackbaud-sky':
        await this._navigationService.navigateToBlackbaudSkyAccount(this.configId, externalAccountId);
        break;
      case 'canvas':
        await this._navigationService.navigateToCanvasAccount(this.configId, externalAccountId);
        break;
      case 'google':
        await this._navigationService.navigateToGoogleAccount(this.configId, externalAccountId);
        break;
      case 'schoology':
        await this._navigationService.navigateToSchoologyAccount(this.configId, externalAccountId);
        break;
      case 'veracross':
        await this._navigationService.navigateToVeracrossAccount(this.configId, externalAccountId);
        break;
      case 'veracross-v3':
        await this._navigationService.navigateToVeracrossV3Account(this.configId, externalAccountId);
        break;
      case 'calendars':
        await this._navigationService.navigateToCalendarAccount(this.configId, externalAccountId);
        break;
      case 'managebac':
        await this._navigationService.navigateToManageBacAccount(this.configId, externalAccountId);
        break;
      case 'moodle':
        await this._navigationService.navigateToMoodleAccount(this.configId, externalAccountId);
        break;
      case 'studyo-internal':
        // There's nothing to edit!
        await this.addOrUpdateStudyoInternalAccount(externalAccountId);
        break;
      case 'microsoft-teams':
        await this._navigationService.navigateToMicrosoftTeamsAccount(this.configId, externalAccountId);
        break;
    }
  }

  async navigateToEditSettings(externalAccountId: string, kind: ExternalAccountKind): Promise<void> {
    switch (kind) {
      case 'blackbaud':
        await this._navigationService.navigateToBlackbaudSettings(this.configId, externalAccountId);
        break;
      case 'blackbaud-sky':
        await this._navigationService.navigateToBlackbaudSkyAccountSetup(this.configId, externalAccountId);
        break;
      case 'canvas':
        // The setup part of Canvas is about global options.
        await this._navigationService.navigateToCanvasSetup(this.configId, externalAccountId);
        break;
      case 'google':
        // Settings and mappings under one dialog, for "Save for all", via mappings icon.
        break;
      case 'schoology':
        break;
      case 'veracross':
      case 'veracross-v3':
        break;
      case 'calendars':
        break;
      case 'managebac':
        await this._navigationService.navigateToManageBacSettings(this.configId, externalAccountId);
        break;
      case 'moodle':
        break;
      case 'studyo-internal':
        break;
      case 'microsoft-teams':
        await this._navigationService.navigateToMicrosoftTeamsSettings(this.configId, externalAccountId);
        break;
    }
  }

  async navigateToEditMappings(externalAccountId: string, kind: ExternalAccountKind): Promise<void> {
    switch (kind) {
      case 'blackbaud':
        await this._navigationService.navigateToBlackbaudMappings(this.configId, externalAccountId);
        break;
      case 'blackbaud-sky':
        await this._navigationService.navigateToBlackbaudSkyMappings(this.configId, externalAccountId);
        break;
      case 'canvas':
        // The settings part of Canvas is about mappings. Unfortunate naming I don't want to change
        // with the addition of the explicit mappings icon.
        await this._navigationService.navigateToCanvasSettings(this.configId, externalAccountId);
        break;
      case 'google':
        // Settings and mappings under one dialog, for "Save for all".
        await this._navigationService.navigateToGoogleSettings(this.configId, externalAccountId, false);
        break;
      case 'schoology':
        break;
      case 'veracross':
      case 'veracross-v3':
        break;
      case 'calendars':
        break;
      case 'managebac':
        await this._navigationService.navigateToManageBacMappings(this.configId, externalAccountId);
        break;
      case 'moodle':
        break;
      case 'studyo-internal':
        break;
      case 'microsoft-teams':
        await this._navigationService.navigateToMicrosoftTeamsMappings(this.configId, externalAccountId);
        break;
    }
  }

  async navigateToAssociations(externalAccountId: string, navigate: NavigateFunction): Promise<void> {
    await this._navigationService.navigateToExternalAssociations(this.configId, externalAccountId, navigate);
  }

  async navigateToExternalContentMappings(): Promise<void> {
    await this._navigationService.navigateToExternalContentMappings(this.configId);
  }

  async navigateToChangeIntegrations(): Promise<void> {
    await this._navigationService.navigateToChangeIntegrations(this.configId);
  }

  private async addOrUpdateStudyoInternalAccount(externalAccountId: string) {
    try {
      // The account name is forced to "Studyo", but since there's no UI, it's useless.
      // There are no actual ways to edit an existing account, but we still follow the rules.
      if (externalAccountId === 'new') {
        await this._connectorsStore.studyoInternal.createOrUpdateStudyoInternalAccount(this.configId, '', 'Studyo');
      } else {
        await this._connectorsStore.studyoInternal.createOrUpdateStudyoInternalAccount(
          this.configId,
          externalAccountId,
          'Studyo'
        );
      }
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.connectors;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + '\n' + (error as Error).message
      });
    }
  }

  private getIntegrationAccountKinds(): ExternalAccountKind[] {
    this._accountService.isAllowed;
    return _.compact(this.integrations.map((integration) => getExternalAccountKindFromIntegration(integration))).filter(
      (kind) => this._accountService.isAllowed(this.getExternalAccountAllowedRoles(kind))
    );
  }

  private getExternalAccountAllowedRoles(kind: ExternalAccountKind): AuthorizationRole[] {
    switch (kind) {
      case 'blackbaud':
      case 'blackbaud-sky':
      case 'calendars':
      case 'canvas':
      case 'managebac':
      case 'microsoft-teams':
      case 'moodle':
      case 'schoology':
      case 'veracross':
      case 'veracross-v3':
        return ['admin', 'super-admin'];
      case 'google':
        return ['teacher', 'admin', 'super-admin'];
      case 'studyo-internal':
        return ['super-admin'];
      default:
        return ['super-admin'];
    }
  }

  private canAddMultipleAccounts(kind: ExternalAccountKind) {
    return kind === 'google' || kind === 'canvas' || kind === 'blackbaud';
  }
}
