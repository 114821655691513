import { CustomFilterUtils } from '@insights/utils';
import { ExternalAccountListViewModel, ExternalAccountTableData } from '@insights/viewmodels';
import { Action } from '@material-table/core';
import { Box, SxProps, Typography, styled, useTheme } from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { ExternalAccountKind } from '@shared/models/types';
import { TintedImage } from '@shared/rxp/tinted-image';
import { observer } from 'mobx-react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { InsightsMaterialTable } from '../InsightsMaterialTable';
import { Container } from '../layout';

const TableStateKey = 'ExternalAccountList';

export interface ExternalAccountListProps {
  viewModel: ExternalAccountListViewModel;
  sx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
  title?: string;
}

export const ExternalAccountList = observer((props: ExternalAccountListProps) => {
  const { accountService, imageService, localizationService } = useInsightsServices();
  const { sx, className, style, title, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;
  const theme = useTheme();
  const navigate = useNavigate();

  function getImage(kind: ExternalAccountKind, isDisabled: boolean) {
    const externalImages = imageService.studyoImages.tasks.external;

    return (
      <Box sx={{ height: 32, width: 32, filter: isDisabled ? `grayscale(1)` : undefined }}>
        <TintedImage
          source={externalImages.getExternalAccountImage(kind)}
          sx={{ position: 'absolute', height: 32, width: 32 }}
        />
      </Box>
    );
  }

  function renderAssociationsStatus({ account }: ExternalAccountTableData) {
    if (account.associationsCount === 0) {
      return <Typography>&mdash;</Typography>;
    }

    return (
      <Typography variant="body2" color={account.failedAssociationsCount > 0 ? 'error' : 'textPrimary'}>
        {strings.localizedAssociationsStatus(account.failedAssociationsCount, account.associationsCount)}
      </Typography>
    );
  }

  const rowActions: (
    | Action<ExternalAccountTableData>
    | ((rowData: ExternalAccountTableData) => Action<ExternalAccountTableData>)
  )[] = [
    {
      icon: 'view_list',
      iconProps: {
        style: {
          fontSize: 24
        }
      },
      tooltip: strings.viewAssociations,

      onClick: (_, data: ExternalAccountTableData | ExternalAccountTableData[]) => {
        const account = (data as ExternalAccountTableData).account;
        if (account != null) {
          void viewModel.navigateToAssociations(account.id, navigate);
        }
      }
    },
    {
      icon: 'edit',
      iconProps: {
        style: {
          fontSize: 24
        }
      },
      tooltip: strings.editAccount,
      onClick: (_, data: ExternalAccountTableData | ExternalAccountTableData[]) => {
        const account = (data as ExternalAccountTableData).account;
        if (account != null) {
          void viewModel.navigateToEditAccount(account.id, account.kind);
        }
      }
    },
    (rowData) => ({
      icon: 'settings',
      iconProps: {
        style: {
          fontSize: 24,
          // "disabled" not working
          color: viewModel.hasSettings(rowData.account.kind) ? 'black' : 'lightgrey'
        }
      },
      tooltip: strings.editSettings,
      disabled: !viewModel.hasSettings(rowData.account.kind),
      onClick: (_, data: ExternalAccountTableData | ExternalAccountTableData[]) => {
        const account = (data as ExternalAccountTableData).account;
        if (account != null) {
          void viewModel.navigateToEditSettings(account.id, account.kind);
        }
      }
    }),
    (rowData) => ({
      icon: 'category',
      iconProps: {
        style: {
          fontSize: 24,
          // "disabled" not working
          color: viewModel.hasMappings(rowData.account.kind) ? 'black' : 'lightgrey'
        }
      },
      tooltip: strings.editMappings,
      disabled: !viewModel.hasMappings(rowData.account.kind),
      onClick: (_, data: ExternalAccountTableData | ExternalAccountTableData[]) => {
        const account = (data as ExternalAccountTableData).account;
        if (account != null) {
          void viewModel.navigateToEditMappings(account.id, account.kind);
        }
      }
    })
  ];

  const freeActions: Action<ExternalAccountTableData>[] = viewModel.accountKinds.map<Action<ExternalAccountTableData>>(
    (kind) => {
      const isDisabled = !viewModel.isAvailable(kind);

      return {
        icon: () => getImage(kind, isDisabled),
        isFreeAction: true,
        tooltip: isDisabled
          ? strings.localizedDisabledAddAccountTooltip(kind)
          : strings.localizedAddAccountTooltip(kind),
        onClick: () => void viewModel.navigateToAddAccount(kind),
        disabled: isDisabled
      };
    }
  );

  if (freeActions.length > 0) {
    freeActions.push({
      icon: () => <div />,
      isFreeAction: true,
      disabled: true,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick: () => {}
    });
  }

  if (accountService.isAllowed(['super-admin', 'admin'])) {
    freeActions.push({
      icon: 'transform',
      isFreeAction: true,
      tooltip: strings.externalContentMappingsTooltip,
      onClick: () => void viewModel.navigateToExternalContentMappings()
    });
  }

  if (accountService.isAllowed(['super-admin'])) {
    freeActions.push({
      icon: 'settings',
      isFreeAction: true,
      tooltip: strings.enableIntegrations,
      onClick: () => void viewModel.navigateToChangeIntegrations()
    });
  }

  return (
    <Root sx={sx} className={className} style={style}>
      <InsightsMaterialTable
        stateKey={TableStateKey}
        // This is to disable the card contour
        components={{
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>
        }}
        title={title ?? strings.externalAccountListTitle}
        columns={[
          {
            field: 'kind',
            title: strings.kind,
            customFilterAndSearch: (filter: string, rowData: ExternalAccountTableData) =>
              CustomFilterUtils.customFilterAndSearch(filter, rowData.account, (a) => [
                a.kind,
                a.name,
                AccountUtils.getDisplayLastFirstName(viewModel.teachersById[a.accountId]),
                a.email
              ]),
            // TODO: Render an icon
            render: ({ account }: ExternalAccountTableData) => (
              <Typography variant="body1">{strings.accountKindName(account.kind)}</Typography>
            )
          },
          {
            title: strings.name,
            render: ({ account }: ExternalAccountTableData) => {
              const teacher = viewModel.teachersById[account.accountId];
              const name = teacher == null ? account.name : AccountUtils.getDisplayLastFirstName(teacher);

              return <Typography variant="body1">{name}</Typography>;
            }
          },
          {
            field: 'email',
            title: strings.email,
            render: ({ account }: ExternalAccountTableData) => <Typography variant="body1">{account.email}</Typography>
          },
          {
            title: strings.status,
            render: renderAssociationsStatus
          }
        ]}
        data={viewModel.tableData}
        actions={rowActions.concat(freeActions)}
        editable={{
          onRowDelete: ({ account }) => viewModel.deleteAccount(account.id)
        }}
        options={{
          paging: false,
          rowStyle: { backgroundColor: '#fff', verticalAlign: 'top' },
          headerStyle: {
            fontSize: theme.typography.body2.fontSize
          },
          emptyRowsWhenPaging: false,
          actionsColumnIndex: -1,
          draggable: false
        }}
        localization={localizationService.localizedStrings.insights.materialTable}
      />
    </Root>
  );
});

const Root = styled(Container)(() => ({
  '.disabledIcon': {
    filter: `grayscale(1)`
  }
}));
