import { ExternalAccountKind, KeywordMappingKind } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import { ExternalContentMappingsViewModel } from '@insights/viewmodels';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SxProps,
  TextField,
  Typography
} from '@mui/material';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../UseInsightsServicesHook.ts';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition.tsx';
import { ExternalContentMappingKeywords } from './ExternalContentMappingKeywords.tsx';

export interface ExternalContentMappingsProps {
  sx?: SxProps;
  className?: string;
  viewModel: ExternalContentMappingsViewModel;
}

export const ExternalContentMappings = observer((props: ExternalContentMappingsProps) => {
  const { localizationService } = useInsightsServices();
  const { sx, className, viewModel } = props;
  const strings = localizationService.localizedStrings.insights.components.connectors;

  return (
    <Stack sx={sx} className={className} spacing={1}>
      <FormControl sx={{ pb: 2 }}>
        <InputLabel htmlFor="kind-select">{strings.externalContentKeywordMappingKindLabel}</InputLabel>
        <Select
          value={viewModel.keywordMappingKind}
          inputProps={{ id: 'kind-select' }}
          onChange={(event) => (viewModel.keywordMappingKind = event.target.value as KeywordMappingKind)}
        >
          <MenuItem value={KeywordMappingKind.TITLE_KEYWORD}>
            {strings.externalContentKeywordMappingKindTitleLabel}
          </MenuItem>
          <MenuItem value={KeywordMappingKind.CATEGORY_PROPERTY}>
            {strings.externalContentKeywordMappingKindCategoryLabel}
          </MenuItem>
        </Select>
      </FormControl>

      <ExternalContentMappingKeywords viewModel={viewModel} />

      <Typography sx={{ pt: 2 }} variant="subtitle1">
        {strings.externalContentOptionsSubtitle}
      </Typography>

      {viewModel.keywordMappingKind === KeywordMappingKind.TITLE_KEYWORD && (
        <FormControlLabel
          label={strings.removeMatchedKeywordLabel}
          control={
            <Checkbox
              checked={viewModel.shouldRemoveMatchedKeyword}
              onChange={(event) => (viewModel.shouldRemoveMatchedKeyword = event.target.checked)}
            />
          }
        />
      )}

      <FormControlLabel
        label={strings.ignoreUnmatchedContentLabel}
        control={
          <Checkbox
            checked={viewModel.shouldIgnoreUnmatchedContent}
            onChange={(event) => (viewModel.shouldIgnoreUnmatchedContent = event.target.checked)}
          />
        }
      />

      <FormControl fullWidth>
        <InputLabel htmlFor="accounts-select">{strings.externalContentAccountKindsLabel}</InputLabel>
        <Select
          multiple
          value={viewModel.externalAccountKinds}
          inputProps={{ id: 'accounts-select' }}
          onChange={(event) =>
            (viewModel.externalAccountKinds = event.target.value as unknown as ExternalAccountKind[])
          }
        >
          {viewModel.availableAccountKinds.map((kind) => (
            <MenuItem key={`account-kind-${kind}`} value={kind}>
              {strings.externalContentAccountKind(kind)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {viewModel.keywordMappingKind === KeywordMappingKind.TITLE_KEYWORD && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <Typography sx={{ pt: 2 }} variant="subtitle1">
            {strings.externalContentAdvancedOptionsSubtitle}
          </Typography>

          <Autocomplete
            freeSolo
            autoSelect
            options={viewModel.suggestedExpressions}
            value={viewModel.keywordMappingExpression}
            onChange={(_, v) =>
              (viewModel.keywordMappingExpression = typeof v === 'string' ? v : (v?.expression ?? ''))
            }
            getOptionLabel={(o) => (typeof o === 'string' ? o : o.description)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={strings.keywordMappingExpressionLabel}
                helperText={viewModel.selectedExpressionDescription}
              />
            )}
          />
        </AuthorizationRoleCondition>
      )}

      <Typography variant="caption" color="red">
        {viewModel.errorMessage}
      </Typography>
    </Stack>
  );
});
