import { ExternalGradingCondition } from '@buf/studyo_studyo.bufbuild_es/studyo/type_connector_pb';
import {
  ContentDefinition_ContentIcon,
  ContentDefinition_WorkloadLevel
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_contents_pb';

// prettier-ignore
export type UserRole = 'normal-user' | 'root-admin-user';

// prettier-ignore
export type LoginKind =
  'password' |
  'google' |
  'microsoft' |
  'apple';

// prettier-ignore
export type CycleDayEffect =
  'preserve' |
  'sequence' |
  'insert' |
  'reset';

// prettier-ignore
export type ConfigType =
  'standalone' |
  'managed';

// prettier-ignore
export type Integration =
  'undefined' |
  'gradeslam' |
  'google-classroom' |
  'veracross' |
  'canvas' |
  'ical' |
  'blackbaud' |
  'google-classroom-connector' |
  'schoology-connector' |
  'veracross-connector' |
  'managebac-connector' |
  'moodle-connector' |
  'studyo-internal' |
  'microsoft-teams-connector' |
  'blackbaud-sky-connector' |
  'veracross-v3-connector';

export const AllIntegrations: Integration[] = [
  'undefined',
  'gradeslam',
  'google-classroom',
  'veracross',
  'canvas',
  'ical',
  'blackbaud',
  'google-classroom-connector',
  'schoology-connector',
  'veracross-connector',
  'managebac-connector',
  'moodle-connector',
  'microsoft-teams-connector',
  'blackbaud-sky-connector',
  'veracross-v3-connector'
];

// prettier-ignore
export type ScheduleKind =
  'cycle-days' |
  'week' |
  'alternating-weeks' |
  'class-times';

// prettier-ignore
export type CycleDayKind =
  'numbers' |
  'letters' |
  'none';

// prettier-ignore
export type GradeLevelSource =
  'account' |
  'section';

export const AllGradeLevelSources: GradeLevelSource[] = ['account', 'section'];

// prettier-ignore
export type ContentIcon =
  'homework' |
  'exam' |
  'oral' |
  'reading' |
  'lab' |
  'personal' |
  'teamwork' |
  'activity' |
  'message' |
  'minitest' |
  'play' |
  'music' |
  'reminder' |
  'sport' |
  'study' |
  'tutoring' |
  'project' |
  'recuperation' |
  'dance' |
  'library' |
  'signature' |
  'movie' |
  'survey' |
  'videoconference' |
  'art' |
  'workplan';

// prettier-ignore
export const AllContentIcons: ContentIcon[] = [
  'homework',
  'exam',
  'oral',
  'reading',
  'lab',
  'personal',
  'teamwork',
  'activity',
  'message',
  'minitest',
  'play',
  'music',
  'reminder',
  'sport',
  'study',
  'tutoring',
  'project',
  'recuperation',
  'dance',
  'library',
  'signature',
  'movie',
  'survey',
  'videoconference',
  'art',
  'workplan',
];

// prettier-ignore
export const ImportantContentIcons: ContentIcon[] = [
  'homework',
  'exam',
  'oral',
  'lab',
  'teamwork',
  'minitest',
  'play',
  'music',
  'sport',
  'study',
  'project',
  'dance',
  'movie',
];

// prettier-ignore
export const UnplannedContentIcons: ContentIcon[] = [
  'message', 
  'recuperation', 
  'reminder', 
  'tutoring', 
  'library'
];

// prettier-ignore
export type ContentKind =
  'task' |
  'note';

// prettier-ignore
export const AllContentKinds: ContentKind[] = [
  'task',
  'note'
];

// prettier-ignore
export type ContentState =
  'active' |
  'completed' |
  'cancelled';

export const AllContentStates: ContentState[] = ['active', 'cancelled', 'completed'];

// prettier-ignore
export type ContentDueState =
  'active' |
  'today' |
  'late';

// prettier-ignore
export type ContentWorkloadLevel =
  'unknown' |
  'none' |
  'regular' |
  'medium' |
  'major';

// Do not include "unknown"
export const AllContentWorkloadLevels: ContentWorkloadLevel[] = ['none', 'regular', 'medium', 'major'];

export const ImportantContentWorkloadLevels: ContentWorkloadLevel[] = ['medium', 'major'];

// prettier-ignore
export type AttachmentKind =
  'photo' |
  'document' |
  'url' |
  'document-url';

// prettier-ignore
export type AttachmentIcon =
  'photo-thumb' |
  'document' |
  'excel' |
  'image' |
  'keynote' |
  'link' |
  'music' |
  'numbers' |
  'pages' |
  'pdf' |
  'powerpoint' |
  'presentation' |
  'spreadsheet' |
  'text' |
  'video' |
  'word' |
  'zip';

export const AllAttachmentIcons: AttachmentIcon[] = [
  'photo-thumb',
  'document',
  'excel',
  'image',
  'keynote',
  'link',
  'music',
  'numbers',
  'pages',
  'pdf',
  'powerpoint',
  'presentation',
  'spreadsheet',
  'text',
  'video',
  'word',
  'zip'
];

// prettier-ignore
export type PublishTargetKind =
  'section' |
  'accounts';

// prettier-ignore
export type PublishStatus =
  'not-published' |
  'publishing' |
  'published' |
  'publish-error';

// prettier-ignore
export type DayOfWeek =
  'sunday' |
  'monday' |
  'tuesday' |
  'wednesday' |
  'thursday' |
  'friday' |
  'saturday';

export const AllDayOfWeek: DayOfWeek[] = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export function getDayOfWeekNumber(dow: DayOfWeek): number {
  switch (dow) {
    case 'sunday':
      return 0;
    case 'monday':
      return 1;
    case 'tuesday':
      return 2;
    case 'wednesday':
      return 3;
    case 'thursday':
      return 4;
    case 'friday':
      return 5;
    case 'saturday':
      return 6;
  }
}

// prettier-ignore
export type SpecialDaySymbol =
  'none' |
  'square' |
  'star' |
  'upended-star' |
  'symmetric-star' |
  'hollow-square' |
  'seal' |
  'open-bracket' |
  'close-bracket' |
  'circle';

// prettier-ignore
export const OrderedSpecialDaySymbols: SpecialDaySymbol[] = [
  'none',
  'square',
  'hollow-square',
  'circle',
  'seal',
  'symmetric-star',
  'star',
  'upended-star',
  'open-bracket',
  'close-bracket'
];

// prettier-ignore
export const FullSpecialDaySymbols: SpecialDaySymbol[] = [
  'circle',
  'seal',
  'square',
  'star',
  'symmetric-star',
  'upended-star'
];

// prettier-ignore
export type Color =
  'orange' |
  'royal-blue' |
  'mauve' |
  'violet' |
  'bright-green' |
  'lime-green' |
  'leaf-green' |
  'terracotta' |
  'olive' |
  'bright-red' |
  'pale-blue' |
  'kaki' |
  'dark-red' |
  'teal' |
  'bright-blue' |
  'bright-pink' |
  'pale-pink' |
  'lavender' |
  'peach' |
  'dark-blue' |
  'light-bmgray' |
  'medium-bmgray' |
  'darker-bmgray' |
  'darkest-bmgray' |
  'yellow';

export const AllColors: Color[] = [
  'orange',
  'royal-blue',
  'mauve',
  'violet',
  'bright-green',
  'lime-green',
  'leaf-green',
  'terracotta',
  'olive',
  'bright-red',
  'pale-blue',
  'kaki',
  'dark-red',
  'teal',
  'bright-blue',
  'bright-pink',
  'pale-pink',
  'lavender',
  'peach',
  'dark-blue',
  'light-bmgray',
  'medium-bmgray',
  'darker-bmgray',
  'darkest-bmgray',
  'yellow'
];

// prettier-ignore
export type Role =
  'individual' |
  'student' |
  'teacher' |
  'parent' |
  'school-staff' |
  'studyo-staff' |
  'unknown';

export const AllAutoEnrollableRoles: Role[] = ['teacher', 'student'];
export const AllAdminSupportedRoles: Role[] = ['teacher', 'school-staff', 'studyo-staff'];

// prettier-ignore
export type PeriodKind =
  'class' |
  'break' |
  'lunch' |
  'free' |
  'study';

// prettier-ignore
export type SubscriptionStatus =
  'unknown' |
  'trial' |
  'valid' |
  'cancelled' |
  'expired';

// prettier-ignore
export type ProductNumber =
  'none' |
  'studyo';

// prettier-ignore
export type PlatformKind =
  'unknown' |
  'web' |
  'apple';

// prettier-ignore
export type IntervalUnit =
  'day' |
  'week' |
  'month' |
  'year';

// prettier-ignore
export type ActiveUsersKind =
  'teachers-published' |
  'teachers-any' |
  'students-interacted' |
  'students-any';

// prettier-ignore
export type ActiveUsersPeriod =
  'daily' |
  'weekly' |
  'monthly' |
  'hourly' |
  'school-year';

// prettier-ignore
export type AccountSessionsPeriod =
  'per-day' |
  'per-hour';

// prettier-ignore
export type ImportSourceKind =
  'other' |
  'grics' |
  'coba' |
  'plurilogic';

// prettier-ignore
export type FileKind =
  'other' |
  'text' |
  'excel' |
  'csv' |
  'xml' |
  'pdf' |
  'zip' |
  'direct-data';

export const AllFileKinds: FileKind[] = ['csv', 'xml', 'excel', 'pdf', 'text', 'zip', 'direct-data', 'other'];
// We used to think about all onboarding files submitted via import sessions, but that's
// not the case anymore. That's why we limit the choices to the data formats, leaving "other"
// in there in case.
export const ExposedFileKinds: FileKind[] = ['csv', 'xml', 'excel', 'text', 'direct-data', 'other'];

// prettier-ignore
export type FileEncoding =
  'unknown' |
  'utf8' |
  'ansi' |
  'unicode16';

export const AllFileEncodings: FileEncoding[] = ['utf8', 'unicode16', 'ansi', 'unknown'];

// prettier-ignore
export type IncidentArea =
  'general' |
  'parsing' |
  'analysing' |
  'comparing';

// prettier-ignore
export type IncidentSeverity =
  'informative' |
  'warning' |
  'error' |
  'fatal-error';

export const OrderedIncidentSeverities: IncidentSeverity[] = ['informative', 'warning', 'error', 'fatal-error'];

// prettier-ignore
export type ExternalAccountKind =
  'blackbaud' |
  'blackbaud-sky' |
  'calendars' |
  'canvas' |
  'google' |
  'schoology' |
  'veracross' |
  'veracross-v3' |
  'managebac' |
  'moodle' |
  'studyo-internal' |
  'microsoft-teams' |
  'unknown' |
  'any';

// prettier-ignore
export type ContentPublishedState =
  'publishedAll' |
  'publishedMaster' |
  'publishedSlave' |
  'notPublished' |
  'all';

// prettier-ignore
export type OnboardingCodeKind =
  'managed-student' |
  'parent-invitation';

// prettier-ignore
export type MoveCourseOccurrencesDirection =
  'left' |
  'right' |
  'right-skip';

// prettier-ignore
export const PremiumFeatureList = [
  'admin-console',
  'organizational-quotient',
  'all-published-tasks-widget',
  'workload-manager-widget',
  'student-entered-tasks-widget',
  'parent-access'
] as const;

// prettier-ignore
export type PremiumFeature = typeof PremiumFeatureList[number];

// prettier-ignore
export type CanvasGradingCondition =
  'any' |
  'graded' |
  'practice';

export const AllCanvasGradingConditions: CanvasGradingCondition[] = ['any', 'graded', 'practice'];

// prettier-ignore
export type ClassroomGradingCondition =
  'any' |
  'graded' |
  'not-graded';

export const AllClassroomGradingConditions: ClassroomGradingCondition[] = ['any', 'graded', 'not-graded'];

export const OQValueList = ['none', 'red', 'yellow', 'green'] as const;

// prettier-ignore
export type OQValue = typeof OQValueList[number];

// prettier-ignore
export type OnboardingStatus = 
  'not-started' | 
  'in-progress' | 
  'completed' | 
  'archived';

export const AllOnboardingStatuses: OnboardingStatus[] = ['not-started', 'in-progress', 'completed', 'archived'];
export const AllStepOnboardingStatuses: OnboardingStatus[] = ['not-started', 'in-progress', 'completed'];

// prettier-ignore
export type OnboardingTextFormat =
  'plain-text' |
  'markdown' |
  'html';

// prettier-ignore
export type OnboardingParticipantKind =
  'studyo-only' |
  'client-only' |
  'studyo-and-client';

export const AllOnboardingParticipantKinds: OnboardingParticipantKind[] = [
  'studyo-only',
  'client-only',
  'studyo-and-client'
];

// prettier-ignore
export type OnboardingQuestionKind = 
  'simple-text' |
  'large-text' |
  'single-choice' |
  'multiple-choice' |
  'date' |
  'date-time' |
  'time' |
  'file' |
  'sensitive-simple-text';

export const AllOnboardingQuestionKinds: OnboardingQuestionKind[] = [
  'simple-text',
  'large-text',
  'single-choice',
  'multiple-choice',
  'date',
  'date-time',
  'time',
  'file',
  'sensitive-simple-text'
];

export type OnboardingTemplateKind = 'process' | 'step' | 'question';

// prettier-ignore
export type OnboardingOperationKind = 
'unspecified' | 
'create' | 
'update' | 
'update-status' | 
'update-assignees' | 
'update-answer' | 
'delete' | 
'rename' |
'upload-file' |
'update-target-date' |
'update-forced-visibility';

// prettier-ignore
export type ConfigState =
  'preparing' |
  'active' |
  'archived';

export const AllConfigStates: ConfigState[] = ['preparing', 'active', 'archived'];

// prettier-ignore
export type CommentEffect =
  'none' |
  'blocks-step' |
  'unblocks-step';

export type SubscriptionEntitlement = 'parent-access' | 'unknown';

export const AllSubscriptionEntitlements: SubscriptionEntitlement[] = ['parent-access'];

// prettier-ignore
export type EntityListKind = 
  'added-entities' | 
  'updated-entities' | 
  'removed-entities' | 
  'skipped-entities';

export const AllEntityListKinds: EntityListKind[] = [
  'added-entities',
  'updated-entities',
  'removed-entities',
  'skipped-entities'
];

// Some new buf types are used directly.
export const AllExternalGradingConditions: ExternalGradingCondition[] = [
  ExternalGradingCondition.ANY,
  ExternalGradingCondition.GRADED,
  ExternalGradingCondition.NOT_GRADED
];

export const AllBufContentIcons: ContentDefinition_ContentIcon[] = [
  ContentDefinition_ContentIcon.HOMEWORK,
  ContentDefinition_ContentIcon.EXAM,
  ContentDefinition_ContentIcon.ORAL,
  ContentDefinition_ContentIcon.READING,
  ContentDefinition_ContentIcon.LAB,
  ContentDefinition_ContentIcon.PERSONAL,
  ContentDefinition_ContentIcon.TEAMWORK,
  ContentDefinition_ContentIcon.ACTIVITY,
  ContentDefinition_ContentIcon.MESSAGE,
  ContentDefinition_ContentIcon.MINITEST,
  ContentDefinition_ContentIcon.PLAY,
  ContentDefinition_ContentIcon.MUSIC,
  ContentDefinition_ContentIcon.REMINDER,
  ContentDefinition_ContentIcon.SPORT,
  ContentDefinition_ContentIcon.STUDY,
  ContentDefinition_ContentIcon.TUTORING,
  ContentDefinition_ContentIcon.PROJECT,
  ContentDefinition_ContentIcon.RECUPERATION,
  ContentDefinition_ContentIcon.DANCE,
  ContentDefinition_ContentIcon.LIBRARY,
  ContentDefinition_ContentIcon.SIGNATURE,
  ContentDefinition_ContentIcon.MOVIE,
  ContentDefinition_ContentIcon.SURVEY,
  ContentDefinition_ContentIcon.VIDEOCONFERENCE,
  ContentDefinition_ContentIcon.ART,
  ContentDefinition_ContentIcon.WORK_PLAN
];

export const AllBufContentWorkloadLevels: ContentDefinition_WorkloadLevel[] = [
  ContentDefinition_WorkloadLevel.NONE,
  ContentDefinition_WorkloadLevel.REGULAR,
  ContentDefinition_WorkloadLevel.MEDIUM,
  ContentDefinition_WorkloadLevel.MAJOR
];
