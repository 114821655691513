import { css } from '@emotion/css';
import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { MailLock, VpnKey } from '@mui/icons-material';
import { Stack, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { LoginKind } from '@shared/models/types';
import { UserProfile } from '@shared/models/user';
import AppleLogo from '@shared/web/images/assets/studyo/ui_apple.svg?react';
import GoogleLogo from '@shared/web/images/assets/studyo/ui_google.svg?react';
import MicrosoftLogo from '@shared/web/images/assets/studyo/ui_microsoft.svg?react';
import { observer } from 'mobx-react';

export interface UserInfoProps {
  sx?: SxProps;
  className?: string;
  profile: UserProfile;
  accountEmail?: string;
}

export const UserInfo = observer(({ sx, className, profile, accountEmail = '' }: UserInfoProps) => {
  const theme = useTheme();
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.users;

  const doEmailsDiffer =
    profile.email.length > 0 && accountEmail.length > 0 && profile.email.toLowerCase() != accountEmail.toLowerCase();

  return (
    <Stack sx={sx} className={className} direction="row" alignItems="center">
      {profile.userRole === 'root-admin-user' && (
        <Tooltip title={strings.rootAdminTooltip}>
          <VpnKey sx={{ mr: 0.5 }} color="secondary" />
        </Tooltip>
      )}
      {!profile.isEmailConfirmed && (
        <Tooltip title={strings.unconfirmedEmailTooltip}>
          <MailLock sx={{ mr: 0.5 }} color="warning" />
        </Tooltip>
      )}
      {profile.loginKinds.map((kind) => logoForLoginKind(kind))}
      <Stack>
        <Typography variant="body1">{profile.username}</Typography>
        <code style={{ color: theme.palette.grey[400] }}>{profile.userId}</code>
        {doEmailsDiffer && (
          <Typography variant="caption" color="error">
            {strings.emailsDontMatchWarning}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
});

function logoForLoginKind(kind: LoginKind) {
  const logoClassName = css([{ height: 24, width: 24, marginRight: 8 }]);

  switch (kind) {
    case 'apple':
      return <AppleLogo className={logoClassName} />;
    case 'google':
      return <GoogleLogo className={logoClassName} />;
    case 'microsoft':
      return <MicrosoftLogo className={logoClassName} />;
    default:
      return undefined;
  }
}
