// TODO MUI.Box

import {
  Column,
  Container,
  Expanded,
  ExternalContentMappings,
  InsightsButton,
  ObservablePresenter,
  Scroll,
  useViewModelRef
} from '@insights/components';
import { LightInsightsMuiTheme } from '@insights/theme';
import { Dialog, DialogActions, DialogContent, DialogTitle, SxProps, ThemeProvider, createTheme } from '@mui/material';
import { DialogResult } from '@shared/services';
import { observer } from 'mobx-react';
import { useInsightsServices } from '../../../UseInsightsServicesHook.ts';

export interface ExternalContentMappingsDialogProps extends DialogResult<void> {
  sx?: SxProps;
  configId: string;
}

export const ExternalContentMappingsDialog = observer((props: ExternalContentMappingsDialogProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx, onCancel, configId, onSuccess } = props;

  const viewModel = useViewModelRef(viewModelFactory, (factory) =>
    factory.createExternalContentMappings(configId, onSuccess!, onCancel!)
  );

  const strings = localizationService.localizedStrings.insights.views.connectors;
  const data = viewModel.data;
  const canApply = data.state === 'fulfilled' && data.value.hasChanges;
  const canCancel = data.state !== 'pending';

  return (
    <ThemeProvider theme={createTheme(LightInsightsMuiTheme)}>
      <Dialog sx={sx} open={true} maxWidth="md" fullWidth>
        <DialogTitle>{strings.externalKeywordMappingTitle}</DialogTitle>
        <DialogContent>
          <ObservablePresenter
            data={data}
            loadingMessage={strings.loadingExternalContentMappingsMessage}
            errorMessage={strings.loadingExternalContentMappingsError}
            render={(data) => (
              <Scroll direction="y">
                <Container sx={{ p: 0.5 }}>
                  <Column>
                    <Expanded>
                      <ExternalContentMappings viewModel={data} />
                    </Expanded>
                  </Column>
                </Container>
              </Scroll>
            )}
          />
        </DialogContent>
        <DialogActions>
          <InsightsButton sx={{ m: 1 }} isDisabled={!canCancel} onClick={() => viewModel.cancel()}>
            {strings.cancelButton}
          </InsightsButton>
          <InsightsButton sx={{ m: 1 }} isDisabled={!canApply} isDefault onClick={() => void viewModel.apply()}>
            {strings.applyButton}
          </InsightsButton>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
});
